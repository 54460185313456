<template>
    <div class="home">
      <div class="container">
        <Top :activeTitle="activeTitle" :list="list" />
        <div class="update_logs">
          <div class="update_item">
            <div class="update_title">{{gameReportList.newVersion.version_name}}</div>
            <div class="update_content">
              {{gameReportList.newVersion.detail}}
            </div>
          </div>

          <div class="update_item"  v-for="(item, index) in gameReportList.versionList" :key="index">
            <div class="update_title">{{ item.version_name }}</div>
            <div class="update_content">
              {{item.detail}}
            </div>
          </div>
        </div>
      </div>
      <FooterNav  :activeIndex="active"/>
      <FooterText />
    </div>
      
      
      
  </template>
  
  <script>
    import FooterNav from "../components/FooterNav.vue";
    import FooterText from "../components/FooterText.vue";
    import Top from "../components/Top.vue";

  export default {
    components: {
        FooterNav,
        FooterText,
        Top,

    },
    data() {  
      return {
        active: 4,
        activeTitle:1,
        type:2,
        gameReportList:{
          newVersion:{

          },
        },
        samllBannerType:2,
        list:[
          {name:'ข่าวอัพเดท',url:'/UpdateLogs'},
          {name:'ประกาศของเกม',url:'/GameReport'},
        ]
      };
    },
    computed: {

    },
    mounted() {
  
    },
    created() {    
      this.getUpdateLogsList()
    },
    methods:{

      getUpdateLogsList(){
          this.service.post('Home/getUpdateLogsList',{type:this.type})
          .then((res) => {
              this.gameReportList = res.data.data
          }) 
      },
    }
  };
  </script>
  
  <style scoped lang="less">
    .update_logs{
      margin-top: 40px;
      .update_item{
        width: 690px;
        margin-bottom: 50px;
        .update_title{
          font-size: 32px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 36px;
          margin-bottom: 10px;
        }
        .update_content{
          font-size: 26px;
          line-height: 36px;
          color: #FFFFFF;
          white-space: pre-wrap;
        }
      }
    }

  </style>
  
  